class Homepage {
  constructor({fallbackImage, fallbackImageLarge, locale}) {
    this.fallbackImage = fallbackImage;
    this.fallbackImageLarge = fallbackImageLarge;
    this.kalisiDateRangePicker = $('input.kalisi-daterangepicker');
    this.fromField = $('#apartment_search_form_from');
    this.toField = $('#apartment_search_form_to');
    this.fromToDateFormat = 'YYYY-MM-DD';
    this.dateFormat = 'ddd, DD/MM/YY';
    this.maximumRange = 29;

    moment.locale(locale);
    this.daterangepickerOptions = {
      autoUpdateInput: false,
      autoApply: true,
      minDate: moment(),
      dateLimit: { days: this.maximumRange },
      opens: "right",
      locale: {
        format: this.dateFormat,
        monthNames: moment.months()
      }
    };
    this.imageMissingFallback();
    this.carouselInit();
    this.datePickerInit();
  }

  datePickerInit() {
    this.kalisiDateRangePicker.on('apply.daterangepicker', (ev, picker) => {
      this.fromField.val(picker.startDate.format(this.fromToDateFormat));
      this.toField.val(picker.endDate.format(this.fromToDateFormat));
      picker.autoUpdateInput = true;
      this.kalisiDateRangePicker.daterangepicker(picker);
    });

    if (this.kalisiDateRangePicker.val().length == 0) {
      this.daterangepickerOptions['endDate'] = moment().add(1, 'days');
    }

    this.kalisiDateRangePicker.daterangepicker(this.daterangepickerOptions);
  }

  carouselInit() {
    $('[data-slick-slider]').slick({
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false,
      lazyLoad: 'ondemand',
      touchThreshold: 5,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: true,
            centerMode: false,
            swipeToSlide: true,
            centerPadding: '15px'
          }
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            arrows: true,
            centerMode: false,
            centerPadding: '20px'
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });
  }

  imageMissingFallback() {
    $('img').on('error', e => {
      e.target.srcset = '';
      if(e.target.classList.contains('img_carousel')) {
        e.target.src = this.fallbackImageLarge
      } else {
        e.target.src = this.fallbackImage;
      }
    });
  }
}

window.Homepage = Homepage;

export default Homepage;
